.App {
  text-align: center;
  background: linear-gradient(0deg, #1c1c2e 33%, #474773 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.Cover {
  min-height: min(75vh, 75vw);
  min-width: min(75vh, 75vw);
  max-height: min(75vh, 75vw);
  max-width: min(75vh, 75vw);
  box-shadow: 0 0 min(3vh, 3vw) rgba(0,0,0,0.3);
  background-color: #000;
  font-size: min(33vh, 33vw);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: min(2vh, 2vw);
}

.Unknown {
  background-color: #af5e2f;
}

.Cover img {
  width: 100%;
}

a {
  color: #fff;
}
